import React from "react";

// Keep this import for now, but remove it when we're sure we're not using it
// import BlockValveIcon from "./BlockValveIcon";
import DraginoECIcon from "./DraginoECIcon";
import DualValveSeedIcon from "./DualValveSeedIcon";
import { makeIconGraphicProps } from "./MapIcons/DeviceIcon/components/IconGraphic/constants";
import { IconGraphic } from "./MapIcons/DeviceIcon/components/IconGraphic/IconGraphic";
import MoistureSensorIcon from "./MoistureSensorIcon";
import SingleValveSeedIcon from "./SingleValveSeedIcon";
import SproutBlockValveIcon from "./SproutBlockValveIcon";

export default function DeviceIcon(props) {
    const { DeviceType, ...propsForChild } = props;

    const iconGraphicProps = makeIconGraphicProps({
        deviceType: DeviceType,
        manualMode: false,
    });
    if (DeviceType === "seed1v") {
        return <SingleValveSeedIcon {...propsForChild} />;
    }
    if (DeviceType === "seed2v") {
        return <DualValveSeedIcon {...propsForChild} />;
    }
    if (DeviceType === "sproutBlock") {
        return <SproutBlockValveIcon {...propsForChild} />;
    }
    if (DeviceType === "draginoEC") {
        return <DraginoECIcon {...propsForChild} />;
    }
    if (DeviceType === "block") {
        return <IconGraphic {...iconGraphicProps} />;
    }
    if (DeviceType === "msense") {
        return <MoistureSensorIcon {...propsForChild} />;
    }

    return <DualValveSeedIcon {...propsForChild} />;
}
