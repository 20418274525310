import "./MicrovalveStatus.css";

import Typography from "@mui/material/Typography";
import { BufferedAction } from "gardenspadejs/dist/general";
import { withSnackbar } from "notistack";
import React from "react";
import { IrrigationDeviceSensor } from "verdiapi/dist/Models/Devices/IrrigationDeviceSensor";
import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import FocusContext from "../../../../services/mapManagement/FocusContext";
import { GenerateUID, sleep } from "../../../../utils/HelperFunctions";
import { ChoosingValves } from "../../HelpDialogues/RegisteringADevice";
import ZoneSelector from "../DeviceStatusComponents/ZoneSelector";
import SaplingAutomationConfiguration from "../SaplingAutomationConfiguration/SaplingAutomationConfiguration";
import { currentOpenDropdown } from "../Sensors/SensorDropdown";

export class MicrovalveStatusV2Base extends React.Component {
    deviceHasSensors = false;

    deviceType = undefined;

    /**
     *
     * @type {FocusContext}
     */
    focusContext;

    mounted = false;

    numberOfValves = 1;

    /**
     * @param {{Device: Device, StartEditing: Boolean, OnZonesChanged: function(Zone, Zone), ShowValveState: Boolean}} props
     */
    constructor(props) {
        super(props);
        let sensorList = this.props.Device?.connectedSensors || [];

        this.deviceType =
            this.props.DeviceType || this.props.Device?.type || this.props.Device?.model?.type || "seed2v";
        const deviceDetails = DeviceConfigurationsByType[this.deviceType];
        if (!deviceDetails) {
            // TODO: Make more silent
            throw new Error(`Device type ${this.deviceType} is not supported`);
        }
        this.deviceHasSensors = deviceDetails.supportedSensors > 0;
        this.numberOfValves = deviceDetails.valveCount;
        // ensure sensors are populated properly
        if (this.deviceHasSensors) {
            if (!Array.isArray(sensorList) || sensorList.length < 1) {
                sensorList = [new IrrigationDeviceSensor({}), new IrrigationDeviceSensor({})];
            }
            for (let i = 0; i < 2; i++) {
                if (!sensorList[i]) {
                    sensorList[i] = new IrrigationDeviceSensor({});
                }
            }
        }
        let V1Zone;
        let V2Zone;
        if (this.props.Device && this.props.Device.zonesByValve) {
            V1Zone = this.props.Device.zonesByValve[0];
            V2Zone = this.props.Device.zonesByValve[1];
        }
        this.state = {
            EditingValve: undefined,
            zones: [V1Zone, V2Zone],
            sensors: sensorList,
        };
        this.uid = GenerateUID("DS");

        this.changesToSave = {};
        this.tryAutoSave = new BufferedAction(() => {
            console.info("Trying to autosave");
            if (this.props.autoSave && this.props.Device) {
                this.props.Device.edit(this.changesToSave)
                    .then(() => {
                        if (this.props.enqueueSnackbar) {
                            this.props.enqueueSnackbar("auto-saved sensor preferences", {
                                variant: "success",
                            });
                        }
                    })
                    .catch((e) => {
                        console.warn(e);
                        if (this.props.enqueueSnackbar) {
                            this.props.enqueueSnackbar("Could not save sensor preferences", {
                                variant: "error",
                            });
                        }
                    });
            }
        }, 4 * 1000);
        this.tryAutoSave.waitForQuiet = true;
        this.tryAutoSave.risingEdge = false;
    }

    componentDidMount() {
        this.mounted = true;
        let V1Zone;
        let V2Zone;
        FocusContext.onInfoCardChanged.addListener(() => {});
        if (this.props.Device && this.props.Device.zonesByValve) {
            V1Zone = this.props.Device.zonesByValve[0];
            V2Zone = this.props.Device.zonesByValve[1];
        }
        this.setState({
            EditingValve: this.props.editing ? 0 : undefined,
            zones: [V1Zone, V2Zone],
        });
        console.info("Mounting microvalve status, redefining zones");

        this.CheckIfVisible();
        this.focusContext = new FocusContext();
        this.focusContext.name = "microvalve zone focus context";
        this.focusContext.defaultFunction = (m) => {
            if (m.model && m.model.category) {
                if (m.model.category === "zone") {
                    if ((this.state.zones[1] || {}).id === m.id || (this.state.zones[0] || {}).id === m.id) {
                        return "active";
                    }
                    return "inactive";
                }
            }
            return undefined;
        };

        this.focusContext.onInteract = (e, mapEntity, focusContext) => {
            const targetModel = mapEntity.model;
            const originalZones = [...this.state.zones];
            if (this.props.editing) {
                if (targetModel && targetModel.category === "zone") {
                    let newZones = [];
                    if (this.numberOfValves === 1) {
                        newZones = [targetModel, targetModel];
                    } else if (!Number.isNaN(this.state.EditingValve) && this.state.EditingValve < 2) {
                        newZones = [...originalZones];
                        newZones[this.state.EditingValve] = targetModel;
                        if (this.props.autoProgressZones) {
                            if (this.state.EditingValve === 1) {
                                this.state.EditingValve = 0;
                            } else {
                                this.state.EditingValve = 1;
                            }
                        }
                    } else {
                        console.warn("Something went wrong and we can't assign the zone selected");
                        newZones = [...originalZones];
                    }
                    originalZones.forEach((originalZone) => {
                        if (originalZone && !newZones.includes(originalZone)) {
                            this.focusContext.setSelected(originalZone, false);
                            this.focusContext.setInactive(originalZone);
                        }
                    });
                    this.focusContext.setFocused(targetModel, true);
                    this.setState({ zones: newZones });
                    return false;
                }
                return false;
            }
            if (targetModel !== this.Device) {
                FocusContext.releaseStack(focusContext);
                return true;
            }
            return false;
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.editing && !nextProps.editing) {
            FocusContext.allMapEntites.forEach((me) => {
                if (me.model && me.model.category !== "zone") {
                    this.focusContext.setHidden(me, false);
                }
            });
        } else if (!this.props.editing && nextProps.editing) {
            FocusContext.allMapEntites.forEach((me) => {
                if (me.model && me.model.category !== "zone") {
                    this.focusContext.setHidden(me);
                }
            });
        }
        const OldZones = [...this.state.zones];
        if (
            nextState.EditingValve !== undefined ||
            nextProps.editing ||
            nextState.EditingValve !== this.state.EditingValve
        ) {
            // It appears this is some kind of backup. I'm not sure if we need it
            // If both valves are in the same zone
            if (nextState.zones[0] === nextState.zones[1] && nextState.zones[1]) {
                this.focusContext.setFocused(nextState.zones[0]);
            } else {
                nextState.zones.forEach((zone, i) => {
                    if (zone) {
                        if (nextState.EditingValve === i) {
                            this.focusContext.setFocused(zone);
                        } else {
                            this.focusContext.setSelected(zone);
                        }
                    }
                });
            }

            // For each zone, is it the same as it was last state? If there are any mismatches, anyZoneChanges will be true
            const anyZoneChanges = this.state.zones.map((z, i) => nextState.zones[i] === z).includes(false);

            // For each sensor, is it the same as it was last state? If there are any mismatches, anySensorChanges will be true
            const anySensorChanges =
                this.state.sensors.map((s, i) => nextState.sensors[i] === s).includes(false) ||
                this.state.sensors !== nextState.sensors;

            // Deprecated
            if (anyZoneChanges && this.props.OnZonesChanged) {
                this.props.OnZonesChanged(nextState.V1Zone, nextState.V2Zone);
            }
            // Deprecated
            if (anySensorChanges && this.props.OnSensorsChanged) {
                this.props.OnSensorsChanged(nextState.S1Sensor, nextState.S2Sensor);
            }

            // new soloution is onChange
            if ((anyZoneChanges || anySensorChanges) && this.props.onChange) {
                if (anySensorChanges) {
                    console.info("Sensor changes detected");
                }
                if (anyZoneChanges) {
                    console.info("Zone changes detected");
                }
                this.props.onChange({
                    connectedZones: [...nextState.zones],
                    connectedSensors: nextState.sensors,
                });
            }

            OldZones.forEach((z) => {
                // wipe out old zones
                if (z && !nextState.zones.includes(z)) {
                    this.focusContext.setSelected(z, false);
                    this.focusContext.setInactive(z);
                }
            });
        }
        return true;
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.tryAutoSave.pendingExecution) {
            this.tryAutoSave.forceExecute();
        }
        setTimeout(() => {
            if (FocusContext.contextStack.includes(this.focusContext)) {
                FocusContext.releaseStack(this.focusContext);
            }
        }, 50);
    }

    onSensorChangeHandler(newSensor, index, forceSave) {
        if (this.props.Device) {
            this.changesToSave.connectedSensors =
                this.changesToSave.connectedSensors || this.props.Device.connectedSensors.map((v) => v.toJSON());
            this.changesToSave.connectedSensors[index] = newSensor.toJSON();
            console.info("sensor Changes to save:", this.changesToSave);
            if (forceSave) {
                this.tryAutoSave.forceExecute();
            } else {
                this.tryAutoSave.trigger();
            }
        }
        const newState = {};
        newState.sensors = [...this.state.sensors];
        newState.sensors[index] = newSensor;
        this.setState(newState);
    }

    async CheckIfVisible() {
        let element;
        while (!element) {
            element = document.getElementById(this.uid);
        }
        await sleep(500);
        while (this.mounted) {
            await sleep(500);
            requestAnimationFrame(() => {
                if (isElementVisible(element)) {
                    if (!FocusContext.contextStack.includes(this.focusContext)) {
                        FocusContext.pushContextToStack(this.focusContext);
                    }
                } else if (currentOpenDropdown !== this.uid) {
                    if (FocusContext.contextStack.includes(this.focusContext)) {
                        FocusContext.releaseStack(this.focusContext);
                    }
                }
            });
        }
    }

    render() {
        const zoneInfoScores = [0.5, 0];
        const zoneDetails = [
            {
                ZoneName: "No Zone",
                ValveState: "__",
            },
            {
                ZoneName: "No Zone",
                ValveState: "__",
            },
        ];
        this.state.zones.forEach((z, i) => {
            if (z) {
                zoneDetails[i].ZoneName = z.name;
                zoneInfoScores[i]++;
            }
        });

        let indexesOfZonesToShow = [];

        if (this.numberOfValves === 2) {
            indexesOfZonesToShow = [0, 1];
        } else if (this.numberOfValves === 1 && !this.deviceHasSensors) {
            // if zone info scores are equal, default to the first valve
            indexesOfZonesToShow = [zoneInfoScores[1] > zoneInfoScores[0] ? 1 : 0];
        } else if (this.deviceHasSensors) {
            indexesOfZonesToShow = [0, 1];
        }

        const generateValveComponent = (valve, zone) => {
            const alignment = indexesOfZonesToShow.length > 1 && valve === 0 ? "right" : "left";
            return (
                <ZoneSelector
                    device={this.props.Device}
                    parentID={this.uid}
                    showSensor={this.deviceHasSensors}
                    alignment={alignment}
                    valve={valve}
                    zone={zone}
                    editing={this.props.editing}
                    sensor={this.state.sensors[valve]}
                    onSensorChange={(e, forceSave) => {
                        this.onSensorChangeHandler(e, valve, forceSave);
                    }}
                    // TODO: this is disabled, but probably shouldn't be for sensor only devices? Reconsider
                    valveDisabled={valve >= this.numberOfValves && this.numberOfValves !== 0}
                    active={this.state.EditingValve === valve}
                    onInteract={() => {
                        if (this.props.editing && this.state.EditingValve === valve) {
                            return;
                        }
                        if (valve < this.numberOfValves || this.numberOfValves === 0) {
                            this.setState({
                                EditingValve: valve,
                            });
                        }
                    }}
                />
            );
        };
        const valveComponents = indexesOfZonesToShow.map((index) =>
            generateValveComponent(index, this.state.zones[index]),
        );
        const showAutomationOptions =
            this.props.Device?.saplingAutomationSettings?.automationEnabled ||
            (this.props.Device?.saplingNetworkInfo?.version?.firmwareVersion >= 5 &&
                this.props.Device?.saplingNetworkInfo?.version?.deviceVariant === "micro");
        return (
            <div className={"MicrovalveStatusRoot"} id={this.uid}>
                <img className={"MicrovalveStatusImageBackground"} src={"SaplingDoubleMicrovalveSimplified2.webp"} />
                <div className={`MicrovalveStatusBody ${this.props.editing ? " MicrovalveStatusBody--editing " : ""}`}>
                    {valveComponents[0] || null}
                    {valveComponents[1] || null}
                </div>
                {this.props.showHelp && (
                    <ChoosingValves>
                        <Typography
                            variant={"button"}
                            className={"MicrovalveZoneSelectHelp"}
                            style={{ paddingTop: 8, paddingBottom: 8 }}
                            component={"div"}
                        >
                            Help{" "}
                        </Typography>
                    </ChoosingValves>
                )}
                <div className={"MicrovalveStatusSpacer"} />
                {showAutomationOptions && <SaplingAutomationConfiguration device={this.props.Device} />}
                {/* <Typography variant={}>Help </Typography> */}
            </div>
        );
    }
}

export const MicrovalveStatus = withSnackbar(MicrovalveStatusV2Base);

function isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    const efp = function getElementFromPoint(x, y) {
        return document.elementFromPoint(x, y);
    };

    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
        return false;
    }
    const center = [(rect.left + rect.right) / 2, (rect.top + rect.bottom) / 2];

    // Return true if any of its four corners are visible
    return (
        el.contains(efp(center[0], center[1])) ||
        el.contains(efp(rect.left, rect.top)) ||
        el.contains(efp(rect.right, rect.top)) ||
        el.contains(efp(rect.right, rect.bottom)) ||
        el.contains(efp(rect.left, rect.bottom))
    );
}
