import type MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";
import { isAutomated, isSensorOnly } from "./deviceAttributes";
import { getDeviceIrrigationEventState, isIrrigating, percentageOfIrrigationCompleted } from "./irrigationEvent";
import { isManualMode, isManualModeTransitioning } from "./manualMode";
/**
 * Logic for commonly referred to device data
 */
export function composeDeviceState({ model }: { model: MapEntityBase["model"] }) {
    const { shouldBeIrrigating, closestEvent, zoneIrrigationStateUncertain } = getDeviceIrrigationEventState({
        model,
    });

    return {
        isIrrigating: isIrrigating({ model }),
        isManualMode: isManualMode({ model }),
        isManualModeTransitioning: isManualModeTransitioning({ model }),
        isAutomated: isAutomated({ model }),
        isSensorOnly: isSensorOnly({ model }),
        irrigationEventState: {
            shouldBeIrrigating,
            closestEvent,
            zoneIrrigationStateUncertain,
            percentageOfIrrigationCompleted: closestEvent ? percentageOfIrrigationCompleted({ closestEvent }) : null,
        },
    };
}
