/**
 * Historical context for why customizing via notes existed in the first place:
 *
 * "This feature means when you type one of several things into the notes field, the device shows up differently.
 * This is specifically for Cassin as a short term fix, it should be migrated to some kind of proper feature.""
 *
 * suppresswarnings:all means we want to not show warnings for this device. This isn't a feature we should use
 * on a regular basis
 *
 * disabledevice:true suppresses warnings, but also makes the device gray to indicate that it isn't currently in
 * use, this is much better to use.
 */

/**
 * Disable a device via notes
 */
export const NOTES_DISABLED_DEVICE_TEXT = "disabledevice:true";

/**
 * Suppress warnings via notes
 */
export const NOTES_SUPPRESS_WARNINGS_TEXT = "suppresswarnings:all";

/**
 * Trigger a custom error via notes
 */
export const NOTES_CUSTOM_ERROR_PREFIX = "error:";

/**
 * Trigger a custom warning via notes
 */
export const NOTES_CUSTOM_WARNING_PREFIX = "warning:";

/**
 * Defines colors that can be overridden by the notes field
 * TODO: color via notes is included to support legacy setups and will be replaced with a proper system for custom icon colors. ref: SWD-595
 */
export const NOTES_OVERRIDE_COLOR_PREFIX = "color:";
