import { BadgeProps as BadgePropsType } from "./components/Badge";

/**
 * Defines props shared between both full & dot icons
 */
export interface SharedProps {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
    colorParams?: IconColorOptions;
    badgeParams?: BadgeProps;
    size: number;
    dotSize?: number;
    dimmed?: boolean;
}
export type ProgressRingOptions =
    | {
          spinning: true;
          percentage: number;
      }
    | {
          spinning: false;
          percentage?: number;
      };

export type ProgressRingProps =
    | {
          progressRing?: true;
          progressRingOptions: ProgressRingOptions;
      }
    | {
          progressRing?: false;
          progressRingOptions?: ProgressRingOptions;
      };

export type BadgeProps =
    | {
          badge?: true;
          badgeOptions: BadgePropsType;
      }
    | {
          badge?: false;
          badgeOptions?: BadgePropsType;
      };

export type SharedIconProps = SharedProps & ProgressRingProps & BadgeProps;
/**
 * Defines available colors for each icon variant
 */
export type IconColorOptions = {
    shadow: string;
    iconBackground: string;
    border?: string;
    shadowStyle?: SHADOW_STYLE;
};

export enum SHADOW_STYLE {
    SPARSE = "SPARSE",
    DENSE = "DENSE",
}

/**
 * Defines specific icon variants, preset groups of settings
 */
export enum ICON_VARIANT {
    SENSOR = "sensor",
    VALVE = "valve",
    GROUP = "group",
}

/**
 * Types of icon graphics
 */
export enum DEVICE_ICON_GRAPHICS {
    SENSOR = "sensor",
    VALVE = "valve",
    GROUP = "group",
    MANUAL_MODE = "manualMode",
}

/**
 * Types of badges
 */
export enum BADGE_VARIANT {
    ERROR = "error",
    INFO = "info",
    WARNING = "warning",
}

/**
 * Icon size scaling options
 */
export enum SIZES {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
}

/**
 * Colors that can be overridden by users via the notes field
 */
export enum NOTES_OVERRIDE_COLOR {
    BLUE = "blue",
    TEAL = "teal",
    YELLOW = "yellow",
    PURPLE = "purple",
    PINK = "pink",
    BROWN = "brown",
    INDIGO = "indigo",
}
