import { iconGraphicColors, IconGraphicConfig } from "./constants";
import { SVGIcon } from "./SVGIcon";

interface IconGraphicProps extends IconGraphicConfig {
    size?: number;
}
export function IconGraphic({
    icon,
    type,
    centerContent,
    size = 24,
    color = iconGraphicColors.default,
}: IconGraphicProps) {
    const IconComponent = icon;
    if (type === "SVG") {
        return <SVGIcon icon={IconComponent} size={size} />;
    }
    if (type === "Text") {
        return <IconComponent centerContent={centerContent} size={size} color={color} />;
    }
    return <></>;
}
