import * as turf from "@turf/turf";
import L from "leaflet";

import type MapEntityBase from "../mapEntities/MapEntityBase";
import { clusterMapEntities } from "./clustering";
import { MapItems } from "./constants";
import type { ClusterMapItem, DeviceMapItem, IconState, ZoomLayeredIconState } from "./types";

/**
 * Get the zoom levels at which a cluster breaks up into individual devices
 * ie. none of its contained children are part of any further grouped
 * - returns undefined if this level does not exist, or any of its children continue to be grouped
 */
interface CalculateClusterBreakupZoomParams {
    zoom: number;
    clusterMapItem: ClusterMapItem;
    maxZoom: number;
    clusterSignatures: { [zoom: number]: { [clusterMapItemId: string]: string } };
    memberToClusterMap: { [zoom: number]: { [memberId: string]: string } };
}
const calculateClusterBreakupZoom = ({
    zoom,
    clusterMapItem,
    maxZoom,
    clusterSignatures,
    memberToClusterMap,
}: CalculateClusterBreakupZoomParams) => {
    const clusterMemberIds = new Set(clusterMapItem.cluster.features.map((f) => f.properties?.id));

    for (let nextZoom = zoom + 1; nextZoom <= maxZoom; nextZoom++) {
        const someChildFoundInAnotherCluster = Array.from(clusterMemberIds).some((memberId) => {
            const nextClusterId = memberToClusterMap[nextZoom][memberId];
            return (
                nextClusterId &&
                clusterSignatures[nextZoom][nextClusterId] !== clusterSignatures[zoom][clusterMapItem.id]
            );
        });

        if (someChildFoundInAnotherCluster) {
            return undefined;
        }

        const allChildrenUnclustered = Array.from(clusterMemberIds).every(
            (memberId) => !memberToClusterMap[nextZoom][memberId],
        );
        if (allChildrenUnclustered) {
            return nextZoom;
        }
    }

    return undefined;
};

/**
 * Generates a unique signature for a cluster based on its members and location
 */
const generateClusterMapItemSignature = (clusterMapItem: ClusterMapItem) => {
    const memberIds = clusterMapItem.cluster.features
        .map((f) => f.properties?.id)
        .sort()
        .join(",");
    const location = `${clusterMapItem.lat},${clusterMapItem.long}`;
    return `${memberIds}|${location}`;
};

/**
 * Get the zoom level at which a cluster breaks up into individual devices
 */
const getBreakupZoomLevels = (zoomLayeredIconState: ZoomLayeredIconState, maxZoom: number) => {
    // Optimize by caching the cluster signatures and member to cluster map
    const clusterSignatures: { [zoom: number]: { [clusterMapItemId: string]: string } } = {};
    const memberToClusterMap: { [zoom: number]: { [memberId: string]: string } } = {};

    for (let zoom = 0; zoom <= maxZoom; zoom++) {
        clusterSignatures[zoom] = {};
        memberToClusterMap[zoom] = {};
        zoomLayeredIconState[zoom].clusterMapItems.forEach((clusterMapItem) => {
            const signature = generateClusterMapItemSignature(clusterMapItem);
            clusterSignatures[zoom][clusterMapItem.id] = signature;
            clusterMapItem.cluster.features.forEach((feature) => {
                if (feature.properties?.id) {
                    memberToClusterMap[zoom][feature.properties.id] = clusterMapItem.id;
                }
            });
        });
    }

    // Iterate over each zoom level and for each cluster calculate the breakup zoom level
    const breakupZoomLevels: { [zoom: number]: { [clusterMapItemId: string]: number | undefined } } = {};
    for (let zoom = 0; zoom <= maxZoom; zoom++) {
        breakupZoomLevels[zoom] = {};

        zoomLayeredIconState[zoom].clusterMapItems.forEach((clusterMapItem) => {
            breakupZoomLevels[zoom][clusterMapItem.id] = calculateClusterBreakupZoom({
                zoom,
                clusterMapItem,
                maxZoom,
                clusterSignatures,
                memberToClusterMap,
            });
        });
    }

    return breakupZoomLevels;
};

/**
 * Composes map state for a single zoom level
 */
const createIconState = (iconMapEntities: MapEntityBase[], mapZoom: number, mapCenter: L.LatLng): IconState => {
    /**
     * Create clusters and clusterMapItems
     */
    const { clusterMapItems, clusterKeys } = clusterMapEntities(iconMapEntities, mapZoom, mapCenter);

    /**
     * Create deviceMapItems
     */
    const deviceMapItems = iconMapEntities.map(
        (mapEntity) =>
            ({
                id: mapEntity.id,
                clusterKey: clusterKeys[mapEntity.id] ?? undefined,
                type: MapItems.DEVICE,
                lat: mapEntity.lat,
                long: mapEntity.long,
                mapEntity,
            }) as DeviceMapItem,
    );

    return { clusterMapItems, deviceMapItems, clusterKeys };
};

/**
 * Computes the state for the map across all zoom levels
 */
export const computeIconState = async ({
    iconMapEntities,
    mapComponent,
}: {
    iconMapEntities: MapEntityBase[];
    mapComponent: L.Map;
}): Promise<ZoomLayeredIconState> => {
    const zoomOptions = Array.from({ length: 25 }, (_, i) => i);
    const mapCenter = mapComponent.getCenter();

    // Create map state for all zoom levels
    const zoomLayeredIconState: ZoomLayeredIconState = {};
    zoomOptions.forEach((zoom) => {
        zoomLayeredIconState[zoom] = createIconState(iconMapEntities, zoom, mapCenter);
    });

    // Compute breakup zoom levels for each cluster
    const breakupZoomLevels = getBreakupZoomLevels(zoomLayeredIconState, mapComponent.getMaxZoom());

    const result: ZoomLayeredIconState = {};

    // Populate the zoomlayeredIconState with breakupzoom levels
    zoomOptions.forEach((zoom) => {
        const { clusterMapItems, deviceMapItems } = zoomLayeredIconState[zoom];

        result[zoom] = {
            clusterMapItems: clusterMapItems.map((item) => ({
                ...item,
                breakupZoom: breakupZoomLevels[zoom][item.id],
            })),
            deviceMapItems: deviceMapItems.map((item) => ({
                ...item,
            })),
            clusterKeys: zoomLayeredIconState[zoom].clusterKeys,
        };
    });

    return result;
};

/**
 * Given a clusterMapItem and all of the deviceMapItems, get all of the deviceMapItems that are part of the cluster
 */
export const getItemsInCluster = (clusterMapItem: ClusterMapItem, deviceMapItems: DeviceMapItem[]) => {
    const itemsInCluster: DeviceMapItem[] = [];
    turf.featureEach(clusterMapItem.cluster, (feature) => {
        const deviceMapItem = deviceMapItems.find((item) => item.mapEntity.id === feature.properties?.id);
        if (deviceMapItem) {
            itemsInCluster.push(deviceMapItem);
        }
    });
    return itemsInCluster;
};

/**
 * Categories of models that are included in the icon layer
 */
const CategoriesInIconLayer = ["blockValve", "irrigationDevice", "thirdPartyDevice", "SensoterraMoisture"];
export const mapEntityBelongsInIconLayer = ({ mapEntity }: { mapEntity: MapEntityBase }) =>
    Boolean(mapEntity?.model && CategoriesInIconLayer.includes(mapEntity?.model.category));
