import { useEffect, useState } from "react";

import { iconMapZoomScaling, iconSizes } from "../../../components/icons/MapIcons/DeviceIcon/constants";
import GlobalOptions from "../../../utils/GlobalOptions";
import { getMapBreakpoint } from "../constants";

// Gets current icon scaling factor
export const getIconScaling = (zoom: number) => {
    const currentZoomBreakpoint = getMapBreakpoint(zoom);
    return iconMapZoomScaling[currentZoomBreakpoint];
};

// Gets the base size based on the user's icon size preference
export const getUserPreferredIconSize = () => iconSizes[GlobalOptions.userIconSizePreference];

export interface IconSizeHookProps {
    mapComponent: L.Map;
}

// Hook to scale icons size based on map zoom
export const useIconSize = ({ mapComponent }: IconSizeHookProps) => {
    const [iconSizeScaling, setIconSizeScaling] = useState(getIconScaling(mapComponent.getZoom()));

    // Scale icons based on map zoom
    useEffect(() => {
        const debouncedZoom = () => {
            const scaling = getIconScaling(mapComponent.getZoom());
            setIconSizeScaling(scaling);
        };

        if (mapComponent) {
            // Update on map zoom
            mapComponent.on("zoomend", debouncedZoom);
        }
        return () => {
            if (mapComponent) {
                mapComponent.off("zoomend", debouncedZoom);
            }
        };
    }, [mapComponent]);

    return getUserPreferredIconSize() * iconSizeScaling;
};
