import { Box } from "@mui/material";
import { ReactNode } from "react";

interface TextIconProps {
    centerContent: ReactNode;
    size: number;
    color?: string;
}
export function TextIcon({ centerContent, size, color }: TextIconProps) {
    return <Box sx={{ color, fontWeight: "bold", fontSize: `${size}px` }}>{centerContent}</Box>;
}
