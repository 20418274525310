import { MasterIndex } from "verdiapi";
import { DeviceConfiguration, DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import type MapEntityBase from "../mapManagement/mapEntities/MapEntityBase";

export function isAutomated({ model }: { model: MapEntityBase["model"] }) {
    return (
        model.saplingAutomationSettings?.automationEnabled ||
        model.sproutAutomationSettings?.automationEnabled !== "none"
    );
}

export function isSensorOnly({ model }: { model: MapEntityBase["model"] }) {
    const type = model.type as DeviceConfiguration["type"];
    return DeviceConfigurationsByType[type]?.valveCount === 0;
}

export function getConnectedZones({ model }: { model: MapEntityBase["model"] }) {
    return model?.connectedZoneIDs ? [...model.connectedZoneIDs.map((zid: string) => MasterIndex.zone.byID[zid])] : [];
}

export function getDeviceType({ model }: { model: MapEntityBase["model"] }) {
    return model.type as DeviceConfiguration["type"];
}
