import * as turf from "@turf/turf";
import L from "leaflet";
import GlobalOptions from "src/utils/GlobalOptions";

import { getMetersPerPixel } from "../constants";
import type MapEntityBase from "../mapEntities/MapEntityBase";
import { MapItems } from "./constants";
import { getIconScaling, getUserPreferredIconSize } from "./sizing";
import type { Cluster, ClusterMapItem } from "./types";

/**
 * Effectively the distance between edges of icons to cluster. Negative allows for overlap
 *
 * Manually calibrated based on eye-test for now
 */
const CLUSTERING_DISTANCES = {
    // Allows a bit of overlap between icons
    NONE: {
        value: -10,
        breakpoint: 17,
    },
    // Clusters individual fields
    FIELD: {
        value: 20,
        breakpoint: 16,
    },
};

const makeDistanceBetweenIcons = (zoom: number) => {
    const { NONE, FIELD } = CLUSTERING_DISTANCES;
    // Check less than or equal since lower zoom means farther away (zoomed out)
    if (zoom <= FIELD.breakpoint) return FIELD.value;
    return NONE.value;
};

const PROGRESS_RING_SIZE = 2; // allow space for progress ring around icon

interface GetMaxClusteringDistanceOptions {
    zoom: number;
    mapCenter: { lat: number; lng: number };
    bufferInPixels?: number;
}
// Gets the max clustering distance (distance between icon centers) in meters for a given zoom level and map center
const getMaxClusteringDistance = ({
    zoom,
    mapCenter,
    bufferInPixels = makeDistanceBetweenIcons(zoom),
}: GetMaxClusteringDistanceOptions) => {
    const metersPerPixel = getMetersPerPixel(zoom, mapCenter);
    const iconScaling = getIconScaling(zoom);
    const scaledIconRadius = ((getUserPreferredIconSize() + PROGRESS_RING_SIZE) * iconScaling) / 2;
    return metersPerPixel * (scaledIconRadius * 2 + bufferInPixels);
};

// Creates a feature collection of points from map entities (to be consumed by turf.js clustering function)
const createPointsFeatureCollection = (mapEntities: MapEntityBase[]) => {
    const points = mapEntities.map((mapEntity) =>
        turf.point([mapEntity.long, mapEntity.lat], {
            id: mapEntity.id,
        }),
    );
    return turf.featureCollection(points);
};

const findClusterCenter = (cluster: Cluster) => {
    const centroid = turf.centroid(cluster);
    return centroid.geometry.coordinates;
};

/**
 * Transforms cluster FeatureCollection (output of turf.js's clustering function) into ClusterMapItems
 */
const createClusterMapItems = (
    clusteredFeatures: Cluster,
): {
    clusterMapItems: ClusterMapItem[];
    clusterKeys: { [key: string]: number | undefined };
} => {
    const clusterMapItems: ClusterMapItem[] = [];
    // Maps member's ids to cluster ids if clustered
    const clusterKeys: { [key: string]: number | undefined } = {};

    turf.clusterEach(clusteredFeatures, "cluster", (cluster, clusterKey) => {
        // All features in cluster have same dbscan type, so just use first one
        const firstFeature = cluster.features[0];

        // Ignore noise points since they are individual devices (i.e. not part of a group)
        if (firstFeature.properties?.dbscan === "noise") return;

        // Find the centroid of cluster's members
        const [long, lat] = findClusterCenter(cluster);

        // Mark device ids as part of a cluster
        turf.featureEach(cluster, (feature) => {
            if (feature?.properties?.id && clusterKey) {
                clusterKeys[feature.properties.id] = clusterKey;
            }
        });

        // Compose ID based on lat, long, and sorted device IDs
        const containedDeviceIds = new Set<string>();
        turf.featureEach(cluster, (feature) => {
            if (feature?.properties?.id) {
                containedDeviceIds.add(feature.properties.id);
            }
        });

        const id = `${lat}-${long}-${Array.from(containedDeviceIds).sort().join(",")}`;

        clusterMapItems.push({
            id,
            clusterKey,
            type: MapItems.CLUSTER,
            lat,
            long,
            cluster: cluster,
        } as ClusterMapItem);
    });

    return { clusterMapItems, clusterKeys };
};

/**
 * Clusters map entities into clusters based on their lat/long coordinates
 */
export const clusterMapEntities = (mapEntities: MapEntityBase[], mapZoom: number, mapCenter: L.LatLng) => {
    const maxClusteringDistanceMeters = GlobalOptions.enableIconClustering
        ? getMaxClusteringDistance({ zoom: mapZoom, mapCenter })
        : 0;
    const featureCollection = createPointsFeatureCollection(mapEntities);
    const clusters = turf.clustersDbscan(featureCollection, maxClusteringDistanceMeters / 1000, { minPoints: 2 });
    const { clusterMapItems, clusterKeys } = createClusterMapItems(clusters);
    return { clusterMapItems, clusterKeys };
};
