import { Box, SxProps } from "@mui/material";

export interface BadgeProps {
    sx?: SxProps;
    size?: number;
    bgColor: string;
    borderColor: string;
}

/**
 * Badge represents a small badge to indicate some status
 */
export function Badge({ bgColor, borderColor, sx, size = 15 }: BadgeProps) {
    return (
        <Box
            sx={{
                // Pass through click events
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                right: 0,
                height: size,
                width: size,
                borderRadius: "50%",
                backgroundColor: bgColor,
                border: `2px solid ${borderColor}`,
                zIndex: 2,
                ...sx,
            }}
        />
    );
}
