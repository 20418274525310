import { isManualMode } from "../../../../../services/deviceDataAccessors/manualMode";
import type DeviceMapEntityV2 from "../../../../../services/mapManagement/mapEntities/DeviceMapEntityV2";
import type MapEntityBase from "../../../../../services/mapManagement/mapEntities/MapEntityBase";
import {
    makeBadgeProps,
    makeIconColorParams,
    makeProgressRingProps,
    makeWarnings,
} from "../../../../../services/mapManagement/mapEntities/shared";
import { menuIconSize } from "../constants";
import { FullIcon } from "./FullIcon";
import { makeIconGraphicProps } from "./IconGraphic/constants";
import { IconGraphic } from "./IconGraphic/IconGraphic";

interface IconAvatarProps {
    mapEntity: MapEntityBase | DeviceMapEntityV2;
    size?: number;
}
/**
 * A simplified device icon component that renders a FullIcon for deviceMapItem
 * Used in cards and menus without map-specific functionality
 */
export function IconAvatar({ mapEntity, size = menuIconSize }: IconAvatarProps) {
    const { status } = makeWarnings({
        model: mapEntity.model,
        type: mapEntity.model.type,
    });
    const manualMode = isManualMode({ model: mapEntity.model });

    const { ...iconGraphicProps } = makeIconGraphicProps({
        manualMode,
        deviceType: mapEntity.model.type,
    });

    const notesColorParams = makeIconColorParams({
        deviceType: mapEntity.model.type,
        notes: mapEntity.model.notes,
    });

    return (
        <FullIcon
            key={mapEntity.id}
            size={size}
            colorParams={notesColorParams}
            hoverEffect={false}
            {...makeBadgeProps({ deviceStatus: status })}
            {...makeProgressRingProps({ model: mapEntity.model })}
        >
            <IconGraphic size={size * 0.6} {...iconGraphicProps} />
        </FullIcon>
    );
}
